import { FC } from 'react'
import { Button, Fade, Modal, styled } from '@mui/material'
import Spinner from '../loading/spinner'

interface DeleteAssociationModalProps {
  setModal: (value: boolean) => void
  deleteLoading?: boolean
  handleDelete: () => void
  label: string
}

const DeleteModal: FC<DeleteAssociationModalProps> = ({
  setModal,
  deleteLoading,
  handleDelete,
  label,
}) => {
  const ModalContainer = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
  `

  const ModalBody = styled('div')`
    background-color: #fff;
    border-radius: 5px;
    padding: 1rem;
    max-width: 550px;
  `

  const StyledButton = styled(Button)`
    font-size: 0.8rem;
  `

  return (
    <ModalContainer
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open
      onClose={() => setModal(false)}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in>
        <ModalBody>
          <p id="transition-modal-title" className="montserrat bold">
            Delete {label}
          </p>
          <p id="transition-modal-description" className="montserrat">
            Are you sure you want delete this {label}? This action can not be
            undone.
          </p>
          <div style={{ float: 'right', marginTop: '2.5rem' }}>
            <StyledButton
              variant="contained"
              color="primary"
              className="montserrat"
              onClick={() => setModal(false)}
              style={{
                marginRight: '1rem',
                background: 'white',
                color: '#000',
                border: '1px solid #008174',
              }}
            >
              Cancel
            </StyledButton>
            <StyledButton
              variant="contained"
              color="primary"
              className="montserrat"
              onClick={handleDelete}
              style={{
                padding: '0.4rem 1.5rem 0.4rem 1.5rem',
                background: 'red',
              }}
            >
              {deleteLoading ? <Spinner size={22} /> : 'DELETE'}
            </StyledButton>
          </div>
        </ModalBody>
      </Fade>
    </ModalContainer>
  )
}

DeleteModal.defaultProps = {
  deleteLoading: false,
}

export default DeleteModal
